<i18n lang="yaml" src="~/i18n/sort.yml"></i18n>

<template>
  <select :value="currentValue" @change="sortOrderChanged">
    <option v-for="option in sortOptions" :key="option" :value="option">
      {{ option === sp.searchParameters.sort ? t('Sort.sortBy') : '' }}{{ t(`Sort.${option}`) }}
    </option>
  </select>
</template>

<script lang="ts" setup>
import { AppProviderKeys } from '~/providers'
import type { RvSearchSorts } from '~/types/api'
import type { IPSearchParameters } from '~/types/provider/search-parameters'

const { t } = useI18n({ useScope: 'local' })

const sp = inject<IPSearchParameters>(AppProviderKeys.SearchParameters) as IPSearchParameters

const sortOptions = ['Default', 'DistanceASC', 'PriceASC', 'PriceDESC', 'DateCreatedDESC']

const currentValue = computed(() => sp.searchParameters.sort ?? 'Default')

function sortOrderChanged(e: Event) {
  const value = (e.target as HTMLSelectElement).value as unknown as RvSearchSorts
  sp.updateSortOrder(value)
}
</script>

<style lang="scss" scoped>
select {
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.75rem 2rem 0.75rem 0.75rem;
  border: 1px solid $primary-500;
  height: 2.75rem;
  border-radius: 0.25rem;
  color: #495057;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  appearance: none !important;
  width: 100%;

  @media (min-width: 768px) {
    width: 15.625rem;
  }
}
</style>
